/**
 * Utility class for formatting various values
 */
class FormatUtils {
  /**
   * Formats a number of members with abbreviated notation
   * @param {number} count - Number of members to format
   * @returns {string} Formatted member count
   */
  static formatMembersCount(count) {
    if (count >= 1_000_000_000_000) return '∞'; // Trillion+
    if (count >= 1_000_000_000) return `${(count / 1_000_000_000).toFixed(1)}B`; // Billions
    if (count >= 1_000_000) return `${(count / 1_000_000).toFixed(1)}M`; // Millions
    if (count >= 1_000) return `${(count / 1_000).toFixed(1)}K`; // Thousands
    return count.toString(); // Less than 1000
  }
}

export default FormatUtils;
