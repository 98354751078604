import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { FaTwitter, FaLinkedin, FaGithub, FaEnvelope } from 'react-icons/fa';
import { useTheme } from '../contexts/ThemeContext';

const Footer = () => {
  const { themePalette } = useTheme();

  const socialLinks = [
    { icon: FaTwitter, href: 'https://twitter.com/mycosara' },
    { icon: FaLinkedin, href: 'https://linkedin.com/company/mycosara' },
    { icon: FaGithub, href: 'https://github.com/mycosara' },
    { icon: FaEnvelope, href: 'mailto:hello@mycosara.com' }
  ];

  const quickLinks = [
    { name: 'Home', path: '/' },
    { name: 'Events', path: '/events' },
    { name: 'Playground', path: '/playground' },
    { name: 'About', path: '/about' }
  ];

  return (
    <footer 
      style={{ 
        background: `linear-gradient(135deg, ${themePalette.background.DEFAULT} 0%, ${themePalette.background.secondary} 100%)`,
        color: themePalette.text.primary 
      }}
      className="py-16"
    >
      <div className="container mx-auto px-6 grid md:grid-cols-4 gap-12">
        <div>
          <div className="flex items-center space-x-3 mb-6">
            <img 
              src="/logo2.png" 
              alt="MyCosara Logo" 
              className="h-12 w-auto object-contain"
            />
            <h3 
              className="bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent font-bold text-2xl"
              style={{
                backgroundImage: `linear-gradient(to right, ${themePalette.primary.DEFAULT}, ${themePalette.primary.secondary || themePalette.primary.DEFAULT})`
              }}
            >
              MyCosara
            </h3>
          </div>
          <p 
            className="text-sm mb-6"
            style={{ color: themePalette.text.secondary }}
          >
            Empowering global learning through innovative technology and community collaboration.
          </p>
          <div className="flex space-x-4">
            {socialLinks.map((social, index) => (
              <motion.a
                key={index}
                href={social.href}
                target="_blank"
                rel="noopener noreferrer"
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.9 }}
                style={{ color: themePalette.primary.DEFAULT }}
                className="text-2xl hover:opacity-70 transition-colors"
              >
                <social.icon />
              </motion.a>
            ))}
          </div>
        </div>

        <div>
          <h4 
            className="text-lg font-semibold mb-6 border-b pb-3"
            style={{ 
              color: themePalette.primary.DEFAULT,
              borderColor: `${themePalette.text.secondary}20` 
            }}
          >
            Quick Links
          </h4>
          <ul className="space-y-3">
            {quickLinks.map((link) => (
              <motion.li 
                key={link.path}
                whileHover={{ x: 10 }}
                transition={{ type: 'spring', stiffness: 300 }}
              >
                <Link 
                  to={link.path} 
                  style={{ 
                    color: themePalette.text.secondary,
                    ':hover': { color: themePalette.primary.DEFAULT }
                  }}
                  className="hover:opacity-70 transition-colors"
                >
                  {link.name}
                </Link>
              </motion.li>
            ))}
          </ul>
        </div>

        <div>
          <h4 
            className="text-lg font-semibold mb-6 border-b pb-3"
            style={{ 
              color: themePalette.primary.DEFAULT,
              borderColor: `${themePalette.text.secondary}20` 
            }}
          >
            Resources
          </h4>
          <ul className="space-y-3">
            {[
              { name: 'Learning Paths', path: '/paths' },
              { name: 'Community Hub', path: '/community' },
              { name: 'AI Tools', path: '/ai-tools' },
              { name: 'Blog', path: '/blog' }
            ].map((link) => (
              <motion.li 
                key={link.path}
                whileHover={{ x: 10 }}
                transition={{ type: 'spring', stiffness: 300 }}
              >
                <Link 
                  to={link.path} 
                  style={{ 
                    color: themePalette.text.secondary,
                    ':hover': { color: themePalette.primary.DEFAULT }
                  }}
                  className="hover:opacity-70 transition-colors"
                >
                  {link.name}
                </Link>
              </motion.li>
            ))}
          </ul>
        </div>

        <div>
          <h4 
            className="text-lg font-semibold mb-6 border-b pb-3"
            style={{ 
              color: themePalette.primary.DEFAULT,
              borderColor: `${themePalette.text.secondary}20` 
            }}
          >
            Newsletter
          </h4>
          <p 
            className="text-sm mb-4"
            style={{ color: themePalette.text.secondary }}
          >
            Stay updated with our latest learning innovations
          </p>
          <div className="flex">
            <input 
              type="email" 
              placeholder="Your email" 
              style={{
                backgroundColor: themePalette.background.DEFAULT,
                color: themePalette.text.primary,
                borderColor: themePalette.primary.DEFAULT
              }}
              className="w-full px-4 py-2 rounded-l-lg border focus:outline-none focus:ring-2 focus:ring-opacity-50"
            />
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              style={{
                backgroundColor: themePalette.primary.DEFAULT,
                color: themePalette.background.DEFAULT,
                borderColor: themePalette.primary.DEFAULT
              }}
              className="px-4 py-2 rounded-r-lg hover:opacity-90 transition-colors border"
            >
              Subscribe
            </motion.button>
          </div>
        </div>
      </div>

      <div 
        className="container mx-auto px-6 mt-12 pt-6 text-center"
        style={{ 
          borderColor: `${themePalette.text.secondary}20`,
          borderTopWidth: '1px'
        }}
      >
        <p 
          className="text-sm"
          style={{ color: themePalette.text.secondary }}
        >
          &copy; {new Date().getFullYear()} MyCosara. All rights reserved. 
          Crafted with passion for global learning.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
