import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { 
  FaCheck, 
  FaTrophy, 
  FaRocket, 
  FaUsers, 
  FaShieldAlt
} from 'react-icons/fa';
import Layout from '../components/Layout';
import { useTheme } from '../contexts/ThemeContext';
import { fetchPricingPlans } from '../utils/APIUtil';

const PricingCard = ({ plan, price, description, features, buttonText, isPopular, icon: Icon, themePalette }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <motion.div
      initial={{ scale: 0.9, opacity: 0 }}
      whileInView={{ scale: 1, opacity: 1 }}
      transition={{ duration: 0.5 }}
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
      style={{ 
        background: `linear-gradient(135deg, ${themePalette.background.DEFAULT} 0%, ${themePalette.background.secondary} 100%)`,
        color: themePalette.text.primary,
        boxShadow: `0 4px 6px ${themePalette.text.secondary}20`,
        borderColor: isPopular ? themePalette.primary.DEFAULT : themePalette.text.secondary
      }}
      className={`rounded-2xl shadow-2xl p-8 text-center border relative overflow-hidden 
        ${isPopular ? 'border-2 scale-105' : 'hover:scale-105'} transition-transform`}
    >
      {isPopular && (
        <div 
          className="absolute top-4 right-4 px-3 py-1 rounded-full text-sm flex items-center space-x-2"
          style={{
            backgroundColor: themePalette.background.DEFAULT,
            color: themePalette.primary.DEFAULT,
            borderColor: themePalette.primary.DEFAULT,
            borderWidth: '1px'
          }}
        >
          <FaTrophy style={{ color: themePalette.primary.DEFAULT }} />
          <span>Most Popular</span>
        </div>
      )}
      
      <div className="flex justify-center mb-6">
        <Icon 
          size={48} 
          style={{ 
            color: isHovered ? themePalette.primary.DEFAULT : themePalette.text.secondary,
            transition: 'color 0.3s ease'
          }} 
        />
      </div>
      
      <h2 
        className="text-3xl font-bold mb-4"
        style={{ color: themePalette.primary.DEFAULT }}
      >
        {plan}
      </h2>
      <p 
        className="mb-6 h-12"
        style={{ color: themePalette.text.secondary }}
      >
        {description}
      </p>
      
      <div className="mb-8">
        <span 
          className="text-5xl font-extrabold"
          style={{ color: themePalette.text.primary }}
        >
          {price}
        </span>
        <span 
          className="ml-2"
          style={{ color: themePalette.text.secondary }}
        >
          {plan === 'Individual' ? '/ forever' : '/ month'}
        </span>
      </div>
      
      <ul className="mb-8 space-y-4 min-h-[240px]">
        <AnimatePresence>
          {features.map((feature, index) => (
            <motion.li 
              key={index} 
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: index * 0.1 }}
              className="flex items-start justify-start space-x-3 text-left"
              style={{ color: themePalette.text.secondary }}
            >
              <FaCheck 
                className="mt-1 flex-shrink-0 mr-2" 
                style={{ color: themePalette.primary.DEFAULT }} 
              />
              <span className="flex-grow">{feature}</span>
            </motion.li>
          ))}
        </AnimatePresence>
      </ul>
      
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        style={{
          backgroundColor: isHovered ? themePalette.primary.DEFAULT : themePalette.background.DEFAULT,
          color: isHovered ? themePalette.background.DEFAULT : themePalette.primary.DEFAULT,
          borderColor: themePalette.primary.DEFAULT,
          borderWidth: '1px',
          transition: 'all 0.3s ease'
        }}
        className="w-full py-3 rounded-full transition-colors border"
      >
        {buttonText}
      </motion.button>
    </motion.div>
  );
};

const Pricing = () => {
  const { themePalette } = useTheme();
  
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);

  const loadPlans = async () => {
    try {
      setLoading(true);
      const { plans: fetchedPlans } = await fetchPricingPlans();
      setPlans(fetchedPlans);
    } catch (error) {
      console.error('Error loading plans:', error);
      setPlans([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadPlans();
  }, []);

  return (
    <Layout>
      <div 
        className="container mx-auto px-6 py-16"
        style={{ 
          background: `linear-gradient(135deg, ${themePalette.background.DEFAULT} 0%, ${themePalette.background.secondary} 100%)`,
          color: themePalette.text.primary 
        }}
      >
        {/* Pricing Plans Section */}
        <section className="text-center mb-16">
          <motion.h1 
            initial={{ y: -50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.6 }}
            style={{ color: themePalette.primary.DEFAULT }}
            className="text-5xl font-bold mb-6"
          >
            AI-Powered Learning for Every Journey
          </motion.h1>
          <motion.p
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.6 }}
            style={{ color: themePalette.text.secondary }}
            className="text-xl max-w-3xl mx-auto"
          >
            Unlock your potential with intelligent, personalized learning experiences
          </motion.p>
        </section>

        {/* Pricing Cards */}
        <div className="grid md:grid-cols-3 gap-8">
          {plans.map((plan, index) => (
            <PricingCard 
              key={index} 
              plan={plan.plan}
              price={plan.price}
              description={plan.description}
              features={plan.features}
              buttonText={plan.buttonText}
              isPopular={plan.isPopular}
              icon={plan.icon === 'FaRocket' ? FaRocket : 
                     plan.icon === 'FaTrophy' ? FaTrophy : 
                     plan.icon === 'FaUsers' ? FaUsers : FaRocket}
              themePalette={themePalette} 
            />
          ))}
        </div>

        {/* Enterprise Contact Section */}
        <motion.section
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.4 }}
          className="mt-16 text-center"
        >
          <div 
            className="max-w-4xl mx-auto p-8 rounded-2xl"
            style={{ 
              background: `linear-gradient(135deg, ${themePalette.background.DEFAULT} 0%, ${themePalette.background.secondary} 100%)`,
              boxShadow: `0 4px 6px ${themePalette.text.secondary}20`
            }}
          >
            <div className="flex justify-center mb-6">
              <FaShieldAlt 
                size={64} 
                style={{ color: themePalette.primary.DEFAULT }} 
              />
            </div>
            <h2 
              className="text-4xl font-bold mb-4"
              style={{ color: themePalette.primary.DEFAULT }}
            >
              Enterprise AI Solutions
            </h2>
            <p 
              className="text-xl mb-6"
              style={{ color: themePalette.text.secondary }}
            >
              Transform your organization&apos;s learning with our cutting-edge AI-driven ecosystem. 
              Tailored intelligence that adapts to your unique business needs.
            </p>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              style={{
                backgroundColor: themePalette.primary.DEFAULT,
                color: themePalette.background.DEFAULT,
              }}
              className="px-8 py-4 rounded-full text-lg font-bold transition-transform"
            >
              Explore Enterprise AI
            </motion.button>
          </div>
        </motion.section>
      </div>
    </Layout>
  );
};

export default Pricing;
