import React, { createContext, useState, useContext, useEffect } from 'react';

// Define theme palettes with more dynamic color mapping
const themePalettes = {
  light: {
    primary: {
      DEFAULT: '#3B82F6',  // Vibrant blue
      dark: '#2563EB',
      light: '#60A5FA'
    },
    text: {
      primary: '#1F2937',  // Dark gray for primary text
      secondary: '#4B5563', // Slightly lighter gray
      muted: '#6B7280'     // Muted gray
    },
    background: {
      DEFAULT: '#F9FAFB',  // Very light gray
      secondary: '#F3F4F6' // Slightly darker light background
    },
    accent: {
      DEFAULT: '#10B981',  // Emerald green
      dark: '#059669',
      light: '#34D399'
    }
  },
  dark: {
    primary: {
      DEFAULT: '#6366F1',  // Indigo
      dark: '#4F46E5',
      light: '#818CF8'
    },
    text: {
      primary: '#E5E7EB',  // Light gray for primary text
      secondary: '#9CA3AF', // Medium gray
      muted: '#6B7280'     // Muted gray
    },
    background: {
      DEFAULT: '#111827',  // Very dark blue-gray
      secondary: '#1F2937' // Slightly lighter dark background
    },
    accent: {
      DEFAULT: '#22C55E',  // Emerald green
      dark: '#16A34A',
      light: '#4ADE80'
    }
  },
  ancient: {
    primary: {
      DEFAULT: '#D97706',  // Amber
      dark: '#B45309',
      light: '#F59E0B'
    },
    text: {
      primary: '#78350F',  // Deep brown
      secondary: '#92400E', // Slightly lighter brown
      muted: '#B45309'     // Muted brown
    },
    background: {
      DEFAULT: '#FEF3C7',  // Light cream
      secondary: '#FDE68A' // Slightly darker cream
    },
    accent: {
      DEFAULT: '#10B981',  // Emerald green
      dark: '#047857',
      light: '#6EE7B7'
    }
  }
};

const ThemeContext = createContext({
  theme: 'light',
  toggleTheme: () => {},
  setTheme: () => {},
  isDarkMode: false,
});

export const ThemeProvider = ({ children }) => {
  const [theme, setThemeState] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme || 'light';
  });

  const isDarkMode = theme === 'dark';

  const setTheme = (newTheme) => {
    const validThemes = Object.keys(themePalettes);
    if (validThemes.includes(newTheme)) {
      setThemeState(newTheme);
    } else {
      console.warn(`Invalid theme: ${newTheme}. Defaulting to light.`);
      setThemeState('light');
    }
  };

  useEffect(() => {
    const root = window.document.documentElement;
    const body = document.body;
    
    // Remove all theme classes
    Object.keys(themePalettes).forEach(cls => {
      root.classList.remove(cls);
      body.classList.remove(cls);
    });
    
    // Add current theme class
    root.classList.add(theme);
    body.classList.add(theme);

    // Get current theme palette
    const currentTheme = themePalettes[theme];

    // Apply theme-specific styles
    body.style.backgroundColor = currentTheme.background.DEFAULT;
    body.style.color = currentTheme.text.primary;
    
    // Set CSS variables for theme
    root.style.setProperty('--theme-background', currentTheme.background.DEFAULT);
    root.style.setProperty('--theme-surface', currentTheme.background.secondary);
    root.style.setProperty('--theme-border', '#ddd');
    root.style.setProperty('--theme-text-primary', currentTheme.text.primary);
    root.style.setProperty('--theme-text-secondary', currentTheme.text.secondary);
    root.style.setProperty('--theme-text-muted', currentTheme.text.muted);
    root.style.setProperty('--theme-accent-primary', currentTheme.accent.DEFAULT);
    root.style.setProperty('--theme-accent-secondary', currentTheme.accent.dark);
    
    // Set font family if specified
    if (theme === 'ancient') {
      body.style.fontFamily = 'Crimson Text, serif';
    }

    // Save to local storage
    localStorage.setItem('theme', theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme(prevTheme => {
      // Cycle through themes: light -> dark -> ancient -> light
      const themeOrder = ['light', 'dark', 'ancient'];
      const currentIndex = themeOrder.indexOf(prevTheme);
      return themeOrder[(currentIndex + 1) % themeOrder.length];
    });
  };

  return (
    <ThemeContext.Provider value={{ 
      theme, 
      toggleTheme, 
      setTheme, 
      isDarkMode,
      themePalette: themePalettes[theme]
    }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
