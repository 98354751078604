import React from 'react';
import { motion } from 'framer-motion';
import { FaAtom, FaNetworkWired, FaGlobeAmericas, FaLightbulb } from 'react-icons/fa';
import Layout from '../components/Layout';
import { useTheme } from '../contexts/ThemeContext';
import aboutData from '../constants/about.json';

// Icon mapping for dynamic icon rendering
const iconMap = {
  FaNetworkWired: FaNetworkWired,
  FaGlobeAmericas: FaGlobeAmericas,
  FaLightbulb: FaLightbulb
};

const About = () => {
  const { themePalette } = useTheme();
  const { visionAndMission, coreValues } = aboutData;

  return (
    <Layout>
      <div 
        className="container mx-auto px-6 py-16 space-y-16"
        style={{ 
          background: `linear-gradient(135deg, ${themePalette.background.DEFAULT} 0%, ${themePalette.background.secondary} 100%)`,
          color: themePalette.text.primary 
        }}
      >
        {/* Hero Section */}
        <section className="text-center">
          <motion.h1 
            initial={{ y: -50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.6 }}
            style={{ color: themePalette.primary.DEFAULT }}
            className="text-5xl font-extrabold mb-6"
          >
            MyCosara: Reimagining Learning
          </motion.h1>
          <motion.p
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.6 }}
            style={{ color: themePalette.text.secondary }}
            className="text-2xl max-w-4xl mx-auto"
          >
            Transforming Education Through Atomic Knowledge Networks
          </motion.p>
        </section>

        {/* Origin Story Section */}
        <section className="grid md:grid-cols-2 gap-12 items-center">
          <motion.div
            initial={{ x: -50, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.6 }}
            className="space-y-6"
          >
            <h2 
              className="text-3xl font-bold mb-4"
              style={{ color: themePalette.primary.DEFAULT }}
            >
              The MyCosara Philosophy
            </h2>
            <p 
              className="text-lg mb-4"
              style={{ color: themePalette.text.secondary }}
            >
              At MyCosara, we believe in the transformative power of knowledge networks. 
            </p>
            <p 
              className="text-lg"
              style={{ color: themePalette.text.secondary }}
            >
              Our name, <span className="bg-gradient-to-r from-blue-500 to-purple-600 text-transparent bg-clip-text font-semibold">MyCoSara</span>, represents a conscious evolution in learning. 
              &quot;My&quot; signifies personal discovery and growth, &quot;Co&quot; represents the power of collaboration, and &quot;Sara&quot; (सार), derived from Sanskrit, stands for “essence,” symbolizing the pursuit of truths and deeper understanding.
            </p>
          </motion.div>
          <motion.div
            initial={{ x: 50, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.6 }}
            style={{ 
              background: `linear-gradient(135deg, ${themePalette.background.DEFAULT} 0%, ${themePalette.background.secondary} 100%)`,
              boxShadow: '0 4px 6px rgba(0,0,0,0.1)'
            }}
            className="rounded-xl p-8"
          >
            <div 
              className="text-6xl mb-6 flex justify-center"
              style={{ color: themePalette.primary.DEFAULT }}
            >
              <FaAtom />
            </div>
            <h3 
              className="text-2xl font-semibold text-center mb-4"
              style={{ color: themePalette.primary.DEFAULT }}
            >
              Atomic Learning Principle
            </h3>
            <p 
              className="text-center"
              style={{ color: themePalette.text.secondary }}
            >
              Knowledge evolves through connection. Our innovative platform transforms individual insights 
              into a dynamic, global learning ecosystem, where every contribution becomes a critical component 
              of collective intelligence and progress.
            </p>
          </motion.div>
        </section>

        {/* Vision and Mission Section */}
        <section 
          style={{ 
            backgroundColor: themePalette.background.secondary,
            color: themePalette.text.primary 
          }}
          className="py-16 rounded-xl"
        >
          <div className="container mx-auto text-center">
            <motion.h2
              initial={{ y: -20, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              className="text-4xl font-bold mb-12"
              style={{ color: themePalette.primary.DEFAULT }}
            >
              Our Vision and Mission
            </motion.h2>
            <div className="grid md:grid-cols-2 gap-12">
              {Object.values(visionAndMission).map((item, index) => {
                const Icon = iconMap[item.icon];
                return (
                  <motion.div
                    key={index}
                    initial={{ scale: 0.9, opacity: 0 }}
                    whileInView={{ scale: 1, opacity: 1 }}
                    transition={{ duration: 0.5 }}
                    style={{ 
                      backgroundColor: themePalette.background.DEFAULT,
                      boxShadow: '0 4px 6px rgba(0,0,0,0.1)'
                    }}
                    className="p-8 rounded-xl"
                  >
                    <div 
                      className="text-5xl mb-6 flex justify-center"
                      style={{ color: themePalette.primary.DEFAULT }}
                    >
                      {Icon && <Icon />}
                    </div>
                    <h3 
                      className="text-2xl font-semibold mb-4"
                      style={{ color: themePalette.primary.DEFAULT }}
                    >
                      {item.title}
                    </h3>
                    <p 
                      className="text-lg"
                      style={{ color: themePalette.text.secondary }}
                    >
                      {item.description}
                    </p>
                  </motion.div>
                );
              })}
            </div>
          </div>
        </section>

        {/* Core Values Section */}
        <section className="text-center">
          <motion.h2
            initial={{ y: -20, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            className="text-4xl font-bold mb-12"
            style={{ color: themePalette.primary.DEFAULT }}
          >
            Our Core Values
          </motion.h2>
          <div className="grid md:grid-cols-3 gap-8">
            {coreValues.map((value, index) => {
              const Icon = iconMap[value.icon];
              return (
                <motion.div
                  key={index}
                  initial={{ scale: 0.9, opacity: 0 }}
                  whileInView={{ scale: 1, opacity: 1 }}
                  transition={{ duration: 0.5, delay: index * 0.2 }}
                  className="p-8 rounded-xl"
                  style={{ 
                    background: `linear-gradient(135deg, ${themePalette.background.DEFAULT} 0%, ${themePalette.background.secondary} 100%)`,
                    boxShadow: `0 4px 6px ${themePalette.text.secondary}20`
                  }}
                >
                  <div 
                    className="text-5xl mb-6 flex justify-center"
                    style={{ color: themePalette.primary.DEFAULT }}
                  >
                    {Icon && <Icon />}
                  </div>
                  <h3 
                    className="text-2xl font-semibold mb-4"
                    style={{ color: themePalette.primary.DEFAULT }}
                  >
                    {value.title}
                  </h3>
                  <p 
                    style={{ color: themePalette.text.secondary }}
                  >
                    {value.description}
                  </p>
                </motion.div>
              );
            })}
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default About;
