import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { 
  FaGlobeAmericas, 
  FaLaptopCode, 
  FaBrain, 
  FaRocket, 
  FaCalendarAlt, 
  FaMapMarkerAlt, 
  FaUsers,
  FaChevronLeft,
  FaChevronRight,
  FaSearch,
  FaTimes
} from 'react-icons/fa';
import Layout from '../components/Layout';
import { useTheme } from '../contexts/ThemeContext';
import { fetchEvents, fetchEventCategories } from '../utils/APIUtil';

// Skeleton Loader Component
const EventCardSkeleton = ({ themePalette }) => (
  <div 
    className="rounded-xl shadow-2xl overflow-hidden animate-pulse"
    style={{ 
      background: `linear-gradient(135deg, ${themePalette.background.DEFAULT} 0%, ${themePalette.background.secondary} 100%)`,
      color: themePalette.text.primary 
    }}
  >
    <div className="p-8">
      <div className="flex justify-between items-center mb-6">
        <div 
          className="w-16 h-16 rounded-full bg-gray-300"
          style={{ backgroundColor: themePalette.text.secondary + '40' }}
        />
        <div 
          className="w-20 h-6 rounded bg-gray-300"
          style={{ backgroundColor: themePalette.text.secondary + '40' }}
        />
      </div>
      <div 
        className="h-8 w-3/4 mb-4 rounded bg-gray-300"
        style={{ backgroundColor: themePalette.text.secondary + '40' }}
      />
      <div 
        className="h-16 w-full mb-6 rounded bg-gray-300"
        style={{ backgroundColor: themePalette.text.secondary + '40' }}
      />
      <div className="space-y-3">
        {[1, 2, 3].map((_, index) => (
          <div 
            key={index} 
            className="h-6 w-full rounded bg-gray-300"
            style={{ backgroundColor: themePalette.text.secondary + '40' }}
          />
        ))}
        <div 
          className="h-12 w-full mt-6 rounded bg-gray-300"
          style={{ backgroundColor: themePalette.text.secondary + '40' }}
        />
      </div>
    </div>
  </div>
);

const Events = () => {
  const { themePalette } = useTheme();
  const location = useLocation();
  const navigate = useNavigate();

  // Extract query parameters
  const searchParams = new URLSearchParams(location.search);
  const initialQuery = searchParams.get('q') || '';
  const initialCategory = searchParams.get('category') || 'All';
  const initialPage = parseInt(searchParams.get('page') || '1', 10);

  const [searchQuery, setSearchQuery] = useState(initialQuery);
  const [activeFilter, setActiveFilter] = useState(initialCategory);
  const [events, setEvents] = useState([]);
  const [categories, setCategories] = useState(['All']);
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(true);
  const [categoriesLoading, setCategoriesLoading] = useState(true);

  const loadCategories = async () => {
    try {
      setCategoriesLoading(true);
      const { categories: fetchedCategories } = await fetchEventCategories();
      setCategories(fetchedCategories);
    } catch (error) {
      console.error('Error loading categories:', error);
    } finally {
      setCategoriesLoading(false);
    }
  };

  const loadEvents = async (page, query = searchQuery, category = activeFilter) => {
    try {
      setLoading(true);
      
      // Prepare search parameters
      const searchParams = {
        query,
        filters: {
          ...(category !== 'All' && { category: [category] })
        }
      };

      const { events: fetchedEvents, hasMore } = await fetchEvents(page, 6, searchParams);
      
      setEvents(fetchedEvents);
      setCurrentPage(page);
      setHasMore(hasMore);

      // Update URL with search parameters
      const newSearchParams = new URLSearchParams();
      if (query) newSearchParams.set('q', query);
      if (category !== 'All') newSearchParams.set('category', category);
      if (page > 1) newSearchParams.set('page', page);
      
      navigate(`/events?${newSearchParams.toString()}`, { replace: true });
    } catch (error) {
      console.error('Error loading events:', error);
      setEvents([]);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadCategories();
    loadEvents(currentPage);
  }, []);

  const getEventIcon = (iconName) => {
    const iconMap = {
      'FaGlobeAmericas': FaGlobeAmericas,
      'FaLaptopCode': FaLaptopCode,
      'FaBrain': FaBrain,
      'FaRocket': FaRocket,
      'FaCalendarAlt': FaCalendarAlt,
      'FaMapMarkerAlt': FaMapMarkerAlt,
      'FaUsers': FaUsers
    };
    const IconComponent = iconMap[iconName];
    return IconComponent ? <IconComponent /> : null;
  };

  const handleSearch = (e) => {
    e.preventDefault();
    loadEvents(1, searchQuery, activeFilter);
  };

  const clearSearch = () => {
    setSearchQuery('');
    loadEvents(1, '', 'All');
  };

  return (
    <Layout>
      <div 
        className="container mx-auto px-6 space-y-16"
        style={{ 
          background: `linear-gradient(135deg, ${themePalette.background.DEFAULT} 0%, ${themePalette.background.secondary} 100%)`,
          color: themePalette.text.primary 
        }}
      >
        {/* Header */}
        <header 
          className="text-center py-16"
          style={{ 
            background: `linear-gradient(135deg, ${themePalette.background.DEFAULT} 0%, ${themePalette.background.secondary} 100%)`,
            color: themePalette.text.primary
          }}
        >
          <h1 
            className="text-5xl font-bold mb-6"
            style={{ color: themePalette.primary.DEFAULT }}
          >
            Upcoming Events
          </h1>
          <p 
            className="text-xl max-w-3xl mx-auto opacity-90"
            style={{ color: themePalette.text.secondary }}
          >
            Join our global community of learners and educators in transformative learning experiences.
          </p>
        </header>

        {/* Search and Filters */}
        <div className="flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-4 mb-12">
          {/* Search Input */}
          <form 
            onSubmit={handleSearch} 
            className="w-full max-w-md flex items-center"
          >
            <div 
              className="relative w-full group"
              style={{ 
                boxShadow: `0 4px 6px ${themePalette.primary.DEFAULT}20`,
                borderRadius: '9999px'
              }}
            >
              <div 
                className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none"
                style={{ color: themePalette.primary.DEFAULT }}
              >
                <FaRocket className="w-5 h-5 opacity-70" />
              </div>
              <input 
                type="text" 
                placeholder="What kind of events are you interested in ?"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="
                  w-full 
                  pl-12 
                  pr-4 
                  py-3 
                  rounded-full 
                  border 
                  transition-all 
                  duration-300 
                  focus:ring-4 
                  focus:outline-none 
                  group-hover:shadow-lg
                "
                style={{
                  backgroundColor: themePalette.background.secondary,
                  color: themePalette.text.primary,
                  borderColor: themePalette.primary.DEFAULT,
                  borderWidth: '1px',
                  boxShadow: `0 2px 4px ${themePalette.primary.DEFAULT}20`,
                  ringColor: themePalette.primary.DEFAULT + '40'
                }}
              />
              <div 
                className="absolute inset-y-0 right-0 pr-4 flex items-center pointer-events-none opacity-50 group-hover:opacity-100 transition-opacity duration-300"
                style={{ color: themePalette.primary.DEFAULT }}
              >
                <kbd className="bg-gray-100 text-gray-500 text-xs px-2 py-1 rounded-md border border-gray-200 dark:bg-gray-800 dark:border-gray-700">
                  ✨ AI
                </kbd>
              </div>
            </div>
          </form>

          {/* Category Filters */}
          <div className="flex flex-wrap justify-center gap-2">
            {categoriesLoading ? (
              <div className="animate-pulse flex space-x-2">
                {[1, 2, 3, 4].map((_, index) => (
                  <div 
                    key={index} 
                    className="w-20 h-10 rounded-full bg-gray-300"
                    style={{ backgroundColor: themePalette.text.secondary + '40' }}
                  />
                ))}
              </div>
            ) : (
              categories.map((category) => (
                <button
                  key={category}
                  onClick={() => {
                    setActiveFilter(category);
                    loadEvents(1, searchQuery, category);
                  }}
                  style={{
                    backgroundColor: activeFilter === category 
                      ? themePalette.background.secondary 
                      : themePalette.background.DEFAULT,
                    color: activeFilter === category 
                      ? themePalette.primary.DEFAULT 
                      : themePalette.text.primary,
                    borderColor: themePalette.primary.DEFAULT,
                    borderWidth: '1px'
                  }}
                  className="px-4 py-2 rounded-full transition-all text-sm"
                >
                  {category}
                </button>
              ))
            )}
          </div>
        </div>

        {/* Events Grid */}
        {loading ? (
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {[1, 2, 3, 4, 5, 6].map((_, index) => (
              <EventCardSkeleton key={index} themePalette={themePalette} />
            ))}
          </div>
        ) : events.length === 0 ? (
          <div className="text-center text-2xl" style={{ color: themePalette.text.primary }}>
            No events found matching your search.
          </div>
        ) : (
          <>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
              {events.map((event, index) => (
                <div
                  key={index}
                  className="rounded-xl shadow-2xl overflow-hidden hover:scale-105 transition-all"
                  style={{ 
                    background: `linear-gradient(135deg, ${themePalette.background.DEFAULT} 0%, ${themePalette.background.secondary} 100%)`,
                    color: themePalette.text.primary,
                    boxShadow: `0 4px 6px ${themePalette.text.secondary}20`
                  }}
                >
                  <div className="p-8">
                    <div className="flex justify-between items-center mb-6">
                      <div 
                        className="text-5xl"
                        style={{ color: themePalette.primary.DEFAULT }}
                      >
                        {getEventIcon(event.icon)}
                      </div>
                      <span 
                        style={{
                          backgroundColor: themePalette.background.DEFAULT,
                          color: themePalette.primary.DEFAULT,
                          borderColor: themePalette.primary.DEFAULT,
                          borderWidth: '1px'
                        }}
                        className="px-3 py-1 rounded-full text-sm border"
                      >
                        {event.category}
                      </span>
                    </div>
                    <h3 
                      className="text-2xl font-semibold mb-4"
                      style={{ color: themePalette.primary.DEFAULT }}
                    >
                      {event.title}
                    </h3>
                    <p 
                      className="opacity-90 mb-6"
                      style={{ color: themePalette.text.secondary }}
                    >
                      {event.description}
                    </p>
                    <div className="space-y-3">
                      <div 
                        className="flex items-center space-x-3"
                        style={{ color: themePalette.text.secondary }}
                      >
                        <FaCalendarAlt style={{ color: themePalette.primary.DEFAULT }} />
                        <span>{event.date}</span>
                      </div>
                      <div 
                        className="flex items-center space-x-3"
                        style={{ color: themePalette.text.secondary }}
                      >
                        <FaMapMarkerAlt style={{ color: themePalette.primary.DEFAULT }} />
                        <span>{event.location || 'Virtual & Global'}</span>
                      </div>
                      <div 
                        className="flex items-center space-x-3"
                        style={{ color: themePalette.text.secondary }}
                      >
                        <FaUsers style={{ color: themePalette.primary.DEFAULT }} />
                        <span>Open to All Learners</span>
                      </div>
                    </div>
                    <button
                      className="mt-6 w-full px-6 py-3 rounded-full hover:opacity-90 transition-colors border"
                      style={{
                        backgroundColor: themePalette.background.DEFAULT,
                        color: themePalette.primary.DEFAULT,
                        borderColor: themePalette.primary.DEFAULT,
                        borderWidth: '1px'
                      }}
                    >
                      Register Now
                    </button>
                  </div>
                </div>
              ))}
            </div>

            {/* Pagination */}
            <div className="flex justify-center items-center mt-12 space-x-4">
              <button
                onClick={() => loadEvents(currentPage - 1, searchQuery, activeFilter)}
                disabled={currentPage === 1}
                className="p-2 rounded-full disabled:opacity-50"
                style={{
                  backgroundColor: themePalette.background.DEFAULT,
                  color: themePalette.primary.DEFAULT,
                  borderColor: themePalette.primary.DEFAULT,
                  borderWidth: '1px'
                }}
              >
                <FaChevronLeft />
              </button>
              
              <span 
                className="text-lg font-semibold"
                style={{ color: themePalette.text.primary }}
              >
                Page {currentPage}
              </span>
              
              <button
                onClick={() => loadEvents(currentPage + 1, searchQuery, activeFilter)}
                disabled={!hasMore}
                className="p-2 rounded-full disabled:opacity-50"
                style={{
                  backgroundColor: themePalette.background.DEFAULT,
                  color: themePalette.primary.DEFAULT,
                  borderColor: themePalette.primary.DEFAULT,
                  borderWidth: '1px'
                }}
              >
                <FaChevronRight />
              </button>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default Events;
