import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useTheme } from '../contexts/ThemeContext';
import { 
  FaPalette, 
  FaUser,
  FaLightbulb,
  FaPaintBrush,
  FaMoon,
  FaSun,
  FaScroll,
  FaBars,
  FaTimes
} from 'react-icons/fa';

const Navbar = () => {
  const { theme, themePalette, setTheme } = useTheme();
  const [isThemeMenuOpen, setIsThemeMenuOpen] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobileThemePopupOpen, setIsMobileThemePopupOpen] = useState(false);
  const [isMobileUserPopupOpen, setIsMobileUserPopupOpen] = useState(false);

  const themeMenuRef = useRef(null);
  const userMenuRef = useRef(null);
  const themeButtonRef = useRef(null);
  const userButtonRef = useRef(null);
  const mobileMenuRef = useRef(null);

  const navigate = useNavigate();
  const location = useLocation();

  const themeOptions = [
    { 
      name: 'Light', 
      value: 'light', 
      icon: FaSun,
      description: 'Bright and clean interface' 
    },
    { 
      name: 'Dark', 
      value: 'dark', 
      icon: FaMoon,
      description: 'Reduced eye strain, modern look' 
    },
    { 
      name: 'Ancient', 
      value: 'ancient', 
      icon: FaScroll,
      description: 'Warm, manuscript-inspired design' 
    }
  ];

  const navLinks = [
    { name: 'Home', path: '/' },
    { name: 'Events', path: '/events' },
    { name: 'Playground', path: '/playground' },
    { name: 'Pricing', path: '/pricing' },
    { name: 'About', path: '/about' }
  ];

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    const handleClickOutside = (event) => {
      // Theme menu outside click
      if (
        themeMenuRef.current && 
        !themeMenuRef.current.contains(event.target) && 
        !themeButtonRef.current.contains(event.target)
      ) {
        setIsThemeMenuOpen(false);
      }

      // User menu outside click
      if (
        userMenuRef.current && 
        !userMenuRef.current.contains(event.target) && 
        !userButtonRef.current.contains(event.target)
      ) {
        setIsUserMenuOpen(false);
      }

      // Mobile menu outside click
      if (
        mobileMenuRef.current && 
        isMobileMenuOpen && 
        !mobileMenuRef.current.contains(event.target)
      ) {
        setIsMobileMenuOpen(false);
      }

      // Mobile theme popup outside click
      if (
        isMobileThemePopupOpen && 
        !event.target.closest('.mobile-theme-popup') && 
        !event.target.closest('.theme-toggle-button')
      ) {
        setIsMobileThemePopupOpen(false);
      }

      // Mobile user popup outside click
      if (
        isMobileUserPopupOpen && 
        !event.target.closest('.mobile-user-popup') && 
        !event.target.closest('.user-toggle-button')
      ) {
        setIsMobileUserPopupOpen(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMobileMenuOpen, isMobileThemePopupOpen, isMobileUserPopupOpen]);

  const toggleThemeMenu = () => {
    setIsThemeMenuOpen(!isThemeMenuOpen);
    setIsUserMenuOpen(false);
  };

  const toggleUserMenu = () => {
    setIsUserMenuOpen(!isUserMenuOpen);
    setIsThemeMenuOpen(false);
  };

  const handleThemeChange = (newTheme) => {
    setTheme(newTheme);
    setIsThemeMenuOpen(false);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const toggleMobileThemePopup = () => {
    setIsMobileThemePopupOpen(!isMobileThemePopupOpen);
    setIsMobileUserPopupOpen(false);
    setIsMobileMenuOpen(false);
  };

  const toggleMobileUserPopup = () => {
    setIsMobileUserPopupOpen(!isMobileUserPopupOpen);
    setIsMobileThemePopupOpen(false);
    setIsMobileMenuOpen(false);
  };

  const handleNavigation = (path) => {
    navigate(path);
    setIsMobileMenuOpen(false);
  };

  return (
    <nav 
      className={`
        fixed top-0 left-0 right-0 z-50 py-4 transition-all duration-300 
        ${isScrolled ? 'bg-white/90 dark:bg-dark-primary/90 backdrop-blur-md shadow-md' : 'bg-transparent'}
      `}
      style={{
        color: themePalette.text.primary,
        borderBottomColor: themePalette.border
      }}
    >
      <div className="container mx-auto px-6 flex justify-between items-center">
        {/* Logo */}
        <Link 
          to="/" 
          className="text-2xl font-bold flex items-center space-x-2"
          style={{ color: themePalette.text.primary }}
        >
          <img 
            src="/logo2.png" 
            alt="MyCosara Logo" 
            className="h-10 w-auto object-contain"
          />
          <span 
            className="bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent font-bold text-2xl hidden md:inline"
            style={{
              backgroundImage: `linear-gradient(to right, ${themePalette.primary.DEFAULT}, ${themePalette.primary.secondary || themePalette.primary.DEFAULT})`
            }}
          >
            MyCosara
          </span>
        </Link>

        {/* Desktop Navigation */}
        <div className="hidden md:flex items-center space-x-6 justify-end">
          {/* Navigation Links */}
          <div className="flex items-center space-x-6">
            {navLinks.map((link) => (
              <Link 
                key={link.path}
                to={link.path} 
                className={`
                  hover:text-primary transition-colors relative
                  ${location.pathname === link.path 
                    ? 'font-bold text-primary' 
                    : 'text-secondary'}
                `}
                style={{ 
                  color: location.pathname === link.path 
                    ? themePalette.primary.DEFAULT 
                    : themePalette.text.secondary 
                }}
              >
                {link.name}
                {location.pathname === link.path && (
                  <span 
                    className="absolute -bottom-2 left-0 w-full h-1 rounded-full"
                    style={{ 
                      backgroundColor: themePalette.primary.DEFAULT,
                      transition: 'all 0.3s ease'
                    }}
                  />
                )}
              </Link>
            ))}
          </div>

          {/* Theme Switcher */}
          <div className="relative">
            <button 
              ref={themeButtonRef}
              onClick={toggleThemeMenu}
              className="p-2 rounded-full hover:bg-gray-200 dark:hover:bg-dark-secondary transition-colors"
            >
              <FaPalette className="text-xl" />
            </button>
            {isThemeMenuOpen && (
              <div 
                ref={themeMenuRef}
                className="
                  absolute right-0 mt-2 w-72 bg-white dark:bg-dark-primary 
                  shadow-2xl rounded-2xl overflow-hidden border 
                  animate-fade-in-down
                "
                style={{ 
                  background: themePalette.background, 
                  color: themePalette.text.primary,
                  border: `1px solid ${themePalette.border}`
                }}
              >
                <div className="p-4 border-b" style={{ borderBottomColor: themePalette.border }}>
                  <p className="text-sm text-gray-500 dark:text-gray-400">Select Theme</p>
                </div>
                {themeOptions.map((themeOption) => (
                  <button
                    key={themeOption.value}
                    onClick={() => handleThemeChange(themeOption.value)}
                    className={`
                      w-full text-left px-4 py-3 hover:bg-gray-100 dark:hover:bg-dark-secondary 
                      flex items-center space-x-3
                      ${theme === themeOption.value ? 'bg-primary/10 text-primary' : ''}
                    `}
                  >
                    <themeOption.icon className="text-xl" />
                    <div>
                      <span className="block font-semibold">{themeOption.name}</span>
                      <span className="text-xs text-gray-500 dark:text-gray-400">
                        {themeOption.description}
                      </span>
                    </div>
                  </button>
                ))}
              </div>
            )}
          </div>

          {/* User Menu */}
          <div className="relative">
            <button 
              ref={userButtonRef}
              onClick={toggleUserMenu}
              className="p-2 rounded-full hover:bg-gray-200 dark:hover:bg-dark-secondary transition-colors"
            >
              <FaUser className="text-xl" />
            </button>
            {isUserMenuOpen && (
              <div 
                ref={userMenuRef}
                className="
                  absolute right-0 mt-2 w-72 bg-white dark:bg-dark-primary 
                  shadow-2xl rounded-2xl overflow-hidden border 
                  animate-fade-in-down
                "
                style={{ 
                  background: themePalette.background, 
                  color: themePalette.text.primary,
                  border: `1px solid ${themePalette.border}`
                }}
              >
                <div className="p-4 border-b flex items-center space-x-4" style={{ borderBottomColor: themePalette.border }}>
                  <div className="w-12 h-12 bg-gray-200 dark:bg-dark-secondary rounded-full flex items-center justify-center">
                    <FaUser className="text-2xl text-gray-500 dark:text-gray-400" />
                  </div>
                  <div>
                    <p className="font-semibold">Welcome</p>
                    <p className="text-sm text-gray-500 dark:text-gray-400">Sign in to access full features</p>
                  </div>
                </div>
                <div className="py-2">
                  <button className="w-full text-left px-4 py-3 hover:bg-gray-100 dark:hover:bg-dark-secondary flex items-center space-x-3">
                    <FaUser />
                    <span>Sign In</span>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Mobile Menu Toggle */}
        <div className="md:hidden flex items-center space-x-4 relative">
          {/* Theme Popup for Mobile */}
          <button 
            ref={themeButtonRef}
            onClick={toggleMobileThemePopup}
            className="theme-toggle-button p-2 rounded-full hover:bg-gray-200 dark:hover:bg-dark-secondary transition-colors"
          >
            <FaPalette className="text-xl" />
          </button>
          {isMobileThemePopupOpen && (
            <div 
              className="
                mobile-theme-popup absolute top-full right-0 mt-2 w-72 
                bg-white dark:bg-dark-primary shadow-2xl rounded-2xl 
                overflow-hidden border z-50
              "
              style={{ 
                background: themePalette.background, 
                color: themePalette.text.primary,
                border: `1px solid ${themePalette.border}`
              }}
            >
              <div className="p-4 border-b" style={{ borderBottomColor: themePalette.border }}>
                <p className="text-sm text-gray-500 dark:text-gray-400">Select Theme</p>
              </div>
              {themeOptions.map((themeOption) => (
                <button
                  key={themeOption.value}
                  onClick={() => {
                    handleThemeChange(themeOption.value);
                    setIsMobileThemePopupOpen(false);
                  }}
                  className={`
                    w-full text-left px-4 py-3 hover:bg-gray-100 dark:hover:bg-dark-secondary 
                    flex items-center space-x-3
                    ${theme === themeOption.value ? 'bg-primary/10 text-primary' : ''}
                  `}
                >
                  <themeOption.icon className="text-xl" />
                  <div>
                    <span className="block font-semibold">{themeOption.name}</span>
                    <span className="text-xs text-gray-500 dark:text-gray-400">
                      {themeOption.description}
                    </span>
                  </div>
                </button>
              ))}
            </div>
          )}

          {/* User Popup for Mobile */}
          <button 
            ref={userButtonRef}
            onClick={toggleMobileUserPopup}
            className="user-toggle-button p-2 rounded-full hover:bg-gray-200 dark:hover:bg-dark-secondary transition-colors"
          >
            <FaUser className="text-xl" />
          </button>
          {isMobileUserPopupOpen && (
            <div 
              className="
                mobile-user-popup absolute top-full right-0 mt-2 w-72 
                bg-white dark:bg-dark-primary shadow-2xl rounded-2xl 
                overflow-hidden border z-50
              "
              style={{ 
                background: themePalette.background, 
                color: themePalette.text.primary,
                border: `1px solid ${themePalette.border}`
              }}
            >
              <div className="p-4 border-b flex items-center space-x-4" style={{ borderBottomColor: themePalette.border }}>
                <div className="w-12 h-12 bg-gray-200 dark:bg-dark-secondary rounded-full flex items-center justify-center">
                  <FaUser className="text-2xl text-gray-500 dark:text-gray-400" />
                </div>
                <div>
                  <p className="font-semibold">Welcome</p>
                  <p className="text-sm text-gray-500 dark:text-gray-400">Sign in to access full features</p>
                </div>
              </div>
              <div className="py-2">
                <button 
                  className="w-full text-left px-4 py-3 hover:bg-gray-100 dark:hover:bg-dark-secondary flex items-center space-x-3"
                  style={{ color: themePalette.text.secondary }}
                >
                  <FaUser />
                  <span>Sign In</span>
                </button>
              </div>
            </div>
          )}

          {/* Hamburger Menu */}
          <button 
            onClick={toggleMobileMenu}
            className="p-2 rounded-full hover:bg-gray-200 dark:hover:bg-dark-secondary transition-colors"
          >
            {isMobileMenuOpen ? <FaTimes className="text-2xl" /> : <FaBars className="text-2xl" />}
          </button>
        </div>

        {/* Mobile Menu Overlay */}
        {isMobileMenuOpen && (
          <div 
            ref={mobileMenuRef}
            className="
              fixed inset-0 top-16 bg-white dark:bg-dark-primary 
              z-40 overflow-y-auto pb-16
            "
            style={{ 
              background: themePalette.background, 
              color: themePalette.text.primary 
            }}
          >
            <div className="container mx-auto px-6 pt-6 space-y-4">
              {navLinks.map((link) => (
                <button
                  key={link.path}
                  onClick={() => handleNavigation(link.path)}
                  className="
                    w-full text-left py-3 px-4 rounded-lg 
                    hover:bg-gray-100 dark:hover:bg-dark-secondary 
                    transition-colors text-lg
                  "
                  style={{ color: themePalette.text.secondary }}
                >
                  {link.name}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
