import axios from 'axios';
import eventsData from '../constants/events.json';
import projectsData from '../constants/playground.json';
import pricingData from '../constants/pricing.json';

// Utility function to filter events based on search criteria
const filterEvents = (events, searchParams) => {
  const { 
    query = '', 
    filters = {}, 
    sort = 'date,asc' 
  } = searchParams;

  let filteredEvents = events;

  // Text search across multiple fields
  if (query) {
    const lowercaseQuery = query.toLowerCase();
    filteredEvents = filteredEvents.filter(event => 
      event.title.toLowerCase().includes(lowercaseQuery) ||
      event.description.toLowerCase().includes(lowercaseQuery) ||
      event.category.toLowerCase().includes(lowercaseQuery)
    );
  }

  // Category filter
  if (filters.category && filters.category.length > 0) {
    filteredEvents = filteredEvents.filter(event => 
      filters.category.some(cat => event.category.toLowerCase() === cat.toLowerCase())
    );
  }

  // Location filter
  if (filters.location) {
    filteredEvents = filteredEvents.filter(event => 
      event.location.toLowerCase().includes(filters.location.toLowerCase())
    );
  }

  // Date range filter
  if (filters.dateRange) {
    const { start, end } = filters.dateRange;
    filteredEvents = filteredEvents.filter(event => {
      const eventDate = new Date(event.date);
      const startDate = start ? new Date(start) : null;
      const endDate = end ? new Date(end) : null;
      
      return (!startDate || eventDate >= startDate) && 
             (!endDate || eventDate <= endDate);
    });
  }

  // Sorting
  const [sortField, sortOrder] = sort.split(',');
  filteredEvents.sort((a, b) => {
    if (sortField === 'date') {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
    }
    return 0;
  });

  return filteredEvents;
};

export const fetchEvents = async (page = 1, limit = 6, searchParams = {}) => {
  try {
    // Ensure we're accessing the correct property of the JSON
    const allEvents = eventsData.events || eventsData;
    
    // Apply search and filters
    const filteredEvents = filterEvents(allEvents, searchParams);
    
    // Pagination
    const startIndex = (page - 1) * limit;
    const endIndex = startIndex + limit;
    
    const paginatedEvents = filteredEvents.slice(startIndex, endIndex);
    const hasMore = endIndex < filteredEvents.length;
    
    return {
      events: paginatedEvents,
      hasMore,
      total: filteredEvents.length,
      totalPages: Math.ceil(filteredEvents.length / limit)
    };
  } catch (error) {
    console.error('Error fetching events:', error);
    throw error;
  }
};

export const searchEvents = async (searchParams) => {
  try {
    // Simulate an API search request
    const allEvents = eventsData.events || eventsData;
    
    // Apply search and filters
    const filteredEvents = filterEvents(allEvents, searchParams);
    
    // Pagination
    const page = searchParams.page || 1;
    const size = searchParams.size || 5;
    
    const startIndex = (page - 1) * size;
    const endIndex = startIndex + size;
    
    const paginatedEvents = filteredEvents.slice(startIndex, endIndex);
    
    return {
      content: paginatedEvents,
      pageable: {
        sort: {
          sorted: false,
          unsorted: true,
          empty: true
        },
        pageNumber: page,
        pageSize: size,
        offset: startIndex,
        paged: true,
        unpaged: false
      },
      totalPages: Math.ceil(filteredEvents.length / size),
      totalElements: filteredEvents.length,
      last: endIndex >= filteredEvents.length,
      size: paginatedEvents.length,
      number: page,
      sort: {
        sorted: false,
        unsorted: true,
        empty: true
      },
      first: page === 1,
      numberOfElements: paginatedEvents.length,
      empty: paginatedEvents.length === 0
    };
  } catch (error) {
    console.error('Error searching events:', error);
    throw error;
  }
};

export const fetchEventCategories = async () => {
  try {
    // Ensure we're accessing the correct property of the JSON
    const allEvents = eventsData.events || eventsData;
    
    // Extract unique categories
    const categories = [...new Set(allEvents.map(event => event.category).filter(Boolean))];
    
    return {
      categories: ['All', ...categories]
    };
  } catch (error) {
    console.error('Error fetching event categories:', error);
    return {
      categories: ['All']
    };
  }
};

export const fetchProjects = async (page = 1, limit = 6, searchParams = {}) => {
  try {
    const allProjects = projectsData.projects || projectsData;
    
    // Text and category filtering
    let filteredProjects = allProjects;
    const { query = '', filters = {} } = searchParams;

    if (query) {
      const lowercaseQuery = query.toLowerCase();
      filteredProjects = filteredProjects.filter(project => 
        project.title.toLowerCase().includes(lowercaseQuery) ||
        project.description.toLowerCase().includes(lowercaseQuery) ||
        project.category.toLowerCase().includes(lowercaseQuery)
      );
    }

    if (filters.category && filters.category !== 'All') {
      filteredProjects = filteredProjects.filter(project => 
        project.category.toLowerCase() === filters.category.toLowerCase()
      );
    }
    
    // Pagination
    const startIndex = (page - 1) * limit;
    const endIndex = startIndex + limit;
    
    const paginatedProjects = filteredProjects.slice(startIndex, endIndex);
    const hasMore = endIndex < filteredProjects.length;
    
    return {
      projects: paginatedProjects,
      hasMore,
      total: filteredProjects.length,
      totalPages: Math.ceil(filteredProjects.length / limit)
    };
  } catch (error) {
    console.error('Error fetching projects:', error);
    throw error;
  }
};

export const fetchProjectCategories = async () => {
  try {
    const allProjects = projectsData.projects || projectsData;
    
    // Extract unique categories
    const categories = [...new Set(allProjects.map(project => project.category).filter(Boolean))];
    
    return {
      categories: ['All', ...categories]
    };
  } catch (error) {
    console.error('Error fetching project categories:', error);
    return {
      categories: ['All']
    };
  }
};

export const fetchPricingPlans = async () => {
  try {
    const allPlans = pricingData.pricingPlans || pricingData;
    
    return {
      plans: allPlans
    };
  } catch (error) {
    console.error('Error fetching pricing plans:', error);
    return {
      plans: []
    };
  }
};

export const filterPricingPlans = async (searchParams = {}) => {
  try {
    const allPlans = pricingData.pricingPlans || pricingData;
    
    let filteredPlans = allPlans;
    const { query = '', filters = {} } = searchParams;

    // Text search
    if (query) {
      const lowercaseQuery = query.toLowerCase();
      filteredPlans = filteredPlans.filter(plan => 
        plan.plan.toLowerCase().includes(lowercaseQuery) ||
        plan.description.toLowerCase().includes(lowercaseQuery)
      );
    }

    // Category filter
    if (filters.category && filters.category !== 'All') {
      filteredPlans = filteredPlans.filter(plan => 
        plan.plan.toLowerCase() === filters.category.toLowerCase()
      );
    }

    return {
      plans: filteredPlans,
      total: filteredPlans.length
    };
  } catch (error) {
    console.error('Error filtering pricing plans:', error);
    return {
      plans: [],
      total: 0
    };
  }
};
