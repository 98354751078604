import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';
import { useTheme } from '../contexts/ThemeContext';
import Layout from '../components/Layout';
import homeData from '../constants/home.json';
import {
  FaCode,
  FaNetworkWired,
  FaGlobeAmericas,
  FaBrain,
  FaRocket,
  FaLightbulb,
  FaUsers,
  FaLaptopCode
} from 'react-icons/fa';

// Icon mapping for dynamic icon rendering
const iconMap = {
  FaBrain: FaBrain,
  FaNetworkWired: FaNetworkWired,
  FaGlobeAmericas: FaGlobeAmericas,
  FaLightbulb: FaLightbulb,
  FaUsers: FaUsers,
  FaLaptopCode: FaLaptopCode,
  FaRocket: FaRocket
};

// Modern Glassmorphic Card Component
const GlassMorphicCard = ({ 
  icon: iconName, 
  title, 
  description, 
  isActive = false, 
  onClick = () => {},
  className = ''
}) => {
  const { themePalette } = useTheme();
  const Icon = iconMap[iconName];

  return (
    <motion.div
      whileHover={{ scale: 1.05 }}
      onClick={onClick}
      className={`
        relative overflow-hidden rounded-2xl p-6 
        bg-white/30 backdrop-blur-lg border border-white/20 
        shadow-xl transition-all duration-300 cursor-pointer
        ${isActive ? `ring-2 ring-[${themePalette.primary.DEFAULT}]` : ''}
        ${className}
      `}
      style={{
        borderColor: `${themePalette.background.border || 'rgba(0,0,0,0.1)'}`,
        backgroundColor: `${themePalette.background.secondary}20`, // Add slight transparency
      }}
    >
      <div 
        className="absolute inset-0 bg-gradient-to-br from-white/10 to-white/5 opacity-50" 
        style={{
          backgroundColor: themePalette.background.DEFAULT,
        }}
      />
      <div className="relative z-10">
        <div 
          className="text-4xl mb-4 opacity-80"
          style={{ 
            color: themePalette.primary.DEFAULT 
          }}
        >
          <Icon />
        </div>
        <h3 
          className="text-xl font-semibold mb-2"
          style={{ 
            color: themePalette.text.primary 
          }}
        >
          {title}
        </h3>
        <p 
          className="text-sm"
          style={{ 
            color: themePalette.text.secondary 
          }}
        >
          {description}
        </p>
      </div>
    </motion.div>
  );
};

const Home = () => {
  const { theme, themePalette } = useTheme();
  const [activeSection, setActiveSection] = useState(0);

  const { visionSections, features } = homeData;

  return (
    <Layout>
      <div 
        className="min-h-screen"
        style={{ 
          background: `linear-gradient(135deg, ${themePalette.background.DEFAULT} 0%, ${themePalette.background.secondary} 100%)`,
          color: themePalette.text.primary
        }}
      >
        {/* Hero Section */}
        <motion.section 
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="container mx-auto px-6 py-24 flex flex-col md:flex-row items-center justify-between"
        >
          <div className="md:w-1/2 space-y-6">
            <h1 
              className="text-5xl font-bold leading-tight"
              style={{ color: themePalette.primary.DEFAULT }}
            >
              MyCosara: 
              <br />
              Reimagining Learning
            </h1>
            <p 
              className="text-xl max-w-md"
              style={{ color: themePalette.text.secondary }}
            >
              Transforming education through intelligent, interconnected knowledge networks
            </p>
            <div className="flex space-x-4">
              <Link 
                to="/signin"
                style={{
                  backgroundColor: themePalette.primary.DEFAULT,
                  color: 'white'
                }}
                className="px-8 py-3 rounded-full hover:opacity-90 transition-colors"
              >
                Start Learning
              </Link>
              <Link 
                to="/playground"
                style={{
                  borderColor: themePalette.primary.DEFAULT,
                  color: themePalette.primary.DEFAULT
                }}
                className="px-8 py-3 border rounded-full hover:bg-opacity-10 transition-colors"
              >
                Explore Projects
              </Link>
            </div>
          </div>
          <div className="md:w-1/2 hidden md:block">
            {/* Placeholder for potential hero illustration */}
            <div className="w-full h-96 bg-primary/10 rounded-2xl" />
          </div>
        </motion.section>

        {/* Vision Exploration Section */}
        <section className="container mx-auto px-6 py-16">
          <div className="text-center mb-12">
            <h2 
              className="text-4xl font-bold mb-4"
              style={{ color: themePalette.primary.DEFAULT }}
            >
              Our Visionary Approach
            </h2>
            <p 
              className="text-xl max-w-2xl mx-auto"
              style={{ color: themePalette.text.secondary }}
            >
              Empowering learners through technology, collaboration, and human potential
            </p>
          </div>

          <div className="grid md:grid-cols-3 gap-8">
            {visionSections.map((section, index) => (
              <GlassMorphicCard 
                key={index}
                icon={section.icon}
                title={section.title}
                description={section.description}
                isActive={activeSection === index}
                onClick={() => setActiveSection(index)}
              />
            ))}
          </div>

          <div className="mt-12 text-center">
            <p 
              className="text-lg max-w-3xl mx-auto"
              style={{ color: themePalette.text.secondary }}
            >
              {visionSections[activeSection].details}
            </p>
          </div>
        </section>

        {/* Features Section */}
        <section 
          className="py-16"
          style={{ 
            background: `linear-gradient(135deg, ${themePalette.background.DEFAULT} 0%, ${themePalette.background.secondary} 100%)`,
            color: themePalette.text.primary
          }}
        >
          <div className="container mx-auto px-6">
            <div className="text-center mb-12">
              <h2 
                className="text-4xl font-bold mb-4"
                style={{ color: themePalette.primary.DEFAULT }}
              >
                Transformative Features
              </h2>
              <p 
                className="text-xl max-w-2xl mx-auto"
                style={{ color: themePalette.text.secondary }}
              >
                Innovative tools designed to elevate your learning experience
              </p>
            </div>

            <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
              {features.map((feature, index) => (
                <motion.div
                  key={index}
                  initial={{ scale: 0.9, opacity: 0 }}
                  whileInView={{ scale: 1, opacity: 1 }}
                  transition={{ duration: 0.5, delay: index * 0.2 }}
                  className="text-center p-6 rounded-xl"
                  style={{ 
                    background: `linear-gradient(135deg, ${themePalette.background.DEFAULT} 0%, ${themePalette.background.secondary} 100%)`,
                    boxShadow: `0 4px 6px ${themePalette.text.secondary}20`
                  }}
                >
                  <div 
                    className="text-5xl mb-6 flex justify-center"
                    style={{ color: themePalette.primary.DEFAULT }}
                  >
                    {iconMap[feature.icon] && React.createElement(iconMap[feature.icon])}
                  </div>
                  <h3 
                    className="text-2xl font-semibold mb-4"
                    style={{ color: themePalette.primary.DEFAULT }}
                  >
                    {feature.title}
                  </h3>
                  <p 
                    style={{ color: themePalette.text.secondary }}
                  >
                    {feature.description}
                  </p>
                </motion.div>
              ))}
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default Home;
